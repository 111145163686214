@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
  padding: 0;
  margin: 0;
  /* font-family: "Inter", sans-serif; */
  font-family: 'Play', sans-serif;

}

a {
  color: #84309c;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  color: #e7e8e8;
  background: #070707;
}

.tw-connect-wallet {
  background-color: #070707 !;
}

.css-1mtmiup {
  background-color: #070707 !;
}

.css-1d4s0we a {
  display: none;
}

.css-1d4s0we .css-14k66z6 {
  display: none;
}

.css-1nhje1t {
  display: none;
}

.css-162agw7 {
  display: none;
}

.buycomp {
  background-image: url("../components/images/buypicsmall.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}



@media (min-width:1200px) {


  .buycomp {
    background-image: url("../components/images/buypiclarge.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-size: contain;

  }




}